import { mapState, mapActions } from 'vuex'
import { mdiFile, mdiTrashCanOutline, mdiCog, mdiDrag } from '@mdi/js'
import { EventBus } from '@/utils/EventBus'
import { uniqBy } from '@/utils/utils'
import { v4 as uuidv4 } from 'uuid'
import TemplateCardCases from '../TemplateCardCases.vue'
import QuestionnaireClientCard from '../QuestionnaireClientCard.vue'
import QuestionnaireAnswerDialog from '../QuestionnaireAnswerDialog.vue'
import FileView from '@/components/dialogs/FileView.vue'
import draggable from 'vuedraggable'

export default {
  components: {
    FileView,
    TemplateCardCases,
    QuestionnaireClientCard,
    QuestionnaireAnswerDialog,
    draggable
  },
  data () {
    return {
      loading: true,
      icons: {
        file: mdiFile,
        trash: mdiTrashCanOutline,
        cog: mdiCog,
        drag: mdiDrag
      },
      slider: 50,
      colsLeft: 6,
      colsRight: 6,
      dialog: false,
      selectedView: JSON.parse(localStorage.getItem('case-workbench-document-view')) || 0,
      addNewRequiredDocDialog: false,
      searchText: null,
      activeReqDocuments: [],
      customField: false,
      toolbarActive: true,
      customFieldInput: null,
      dragArea: false,
      dragAreaRequiredDocs: false
    }
  },
  computed: {
    ...mapState({
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases,
      account: state => state.account.account,
      documents: state => state.documents.documents,
      settings: state => state.settings.settings
    }),
    caseData () {
      if (!this.cases || !this.companyCases || !this.groupCases) return []
      const _caseData = this.cases.find(c => c._id === this.$route.params._id) ||
      this.companyCases.find(c => c._id === this.$route.params._id) ||
      this.groupCases.find(c => c._id === this.$route.params._id) || this.caseDataDrawerOverview
      return _caseData
    },
    workbench () {
      if (this.workbenchDrawerOverview) return this.workbenchDrawerOverview
      else return this.caseData && this.caseData.workbenches ? this.caseData.workbenches.find(w => w._id === this.$route.params.workbenchId) : null
    },
    workbenchDocumentsMenuItems () {
      return [
        this.$t('mixed|workbench_documents'),
        this.$t('mixed|required_workbench_documents')
      ]
    },
    reqDocumentFields () {
      if (!this.caseData || !this.caseData.requiredCaseDocuments) return []
      const arr = this.caseData.requiredCaseDocuments
      let onlyDocTypeArr = arr.filter(field => field.fieldType === 'file-input' && field.name !== 'Custom')
      let filteredArr = onlyDocTypeArr
      const filterItems = (arr, query) => {
        return arr.filter((el) => {
          if (Array.isArray(el.name)) {
            el.name.forEach(_name => {
              return _name.toLowerCase().indexOf(query.toLowerCase()) !== -1
            })
          } else {
            return el.name.toLowerCase().includes(query.toLowerCase())
          }
        })
      }
      if (this.searchText && this.searchText.length) {
        filteredArr = filterItems(onlyDocTypeArr, this.searchText)
      }
      return filteredArr
    },
    disabledRule () {
      if (!this.customField && !this.activeReqDocuments.length) {
        return true
      } else if (this.customField && this.customFieldInput === null) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    addNewRequiredDocDialog (value) {
      if (!value) {
        this.resetRequiredDocDialog()
      }
    },
    customFieldInput (value) {
      if (!value || !value.length) {
        this.customFieldInput = null
      }
    },
    workbench (value) {
      if (!value) {
        this.loading = true
      } else {
        this.loading = false
      }
    }
    // slider (value) {
    //   if (value === 40) {
    //     this.colsRight = 7
    //     this.colsLeft = 5
    //   }
    //   if (value === 30) {
    //     this.colsRight = 8
    //     this.colsLeft = 4
    //   }
    //   if (value === 20) {
    //     this.colsRight = 9
    //     this.colsLeft = 3
    //   }
    //   if (value === 50) {
    //     this.colsRight = 6
    //     this.colsLeft = 6
    //   }
    //   if (value === 60) {
    //     this.colsRight = 5
    //     this.colsLeft = 7
    //   }
    //   if (value === 70) {
    //     this.colsRight = 4
    //     this.colsLeft = 8
    //   }
    //   if (value === 80) {
    //     this.colsRight = 3
    //     this.colsLeft = 9
    //   }
    // }
  },
  created () {
    this.$nextTick(() => {
      this.loading = false
    })
    EventBus.$on('drag-area-active', this.onDragAreaActive)
    EventBus.$on('drag-area-not-active', this.onDragAreaNotActive)
    EventBus.$on('drag-area-required-docs-active', this.onDragAreaRequiredDocsActive)
    EventBus.$on('drag-area-required-docs-not-active', this.onDragAreaRequiredDocsNotActive)
  },
  beforDestroy () {
    EventBus.$off('drag-area-active', this.onDragAreaActive)
    EventBus.$off('drag-area-not-active', this.onDragAreaNotActive)
    EventBus.$off('drag-area-required-docs-active', this.onDragAreaRequiredDocsActive)
    EventBus.$off('drag-area-required-docs-not-active', this.onDragAreaRequiredDocsNotActive)
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      fetchCase: 'cases/fetchCase',
      uploadDocument: 'documents/uploadDocument',
      updateCaseWorkbench: 'cases/updateCaseWorkbench',
      updateCompanyCaseWorkbench: 'companyCases/updateCompanyCaseWorkbench',
      updateGroupCaseWorkbench: 'groupCases/updateGroupCaseWorkbench',
      updateCaseWorkbenchTemplate: 'cases/updateCaseWorkbenchTemplate',
      updateQuestionnaireInCase: 'questionnaire/updateQuestionnaireInCase',
      addCaseWorkbenchTemplate: 'cases/addCaseWorkbenchTemplate',
      addCompanyCaseWorkbenchTemplate: 'companyCases/addCompanyCaseWorkbenchTemplate',
      addGroupCaseWorkbenchTemplate: 'groupCases/addGroupCaseWorkbenchTemplate',
      deleteCaseWorkbenchTemplate: 'cases/deleteCaseWorkbenchTemplate',
      deleteCaseWorkbenchDocument: 'cases/deleteCaseWorkbenchDocument',
      deleteCompanyCaseWorkbenchDocument: 'companyCases/deleteCompanyCaseWorkbenchDocument',
      deleteGroupCaseWorkbenchDocument: 'groupCases/deleteGroupCaseWorkbenchDocument'
    }),
    calculateCardHeight (height, width) {
      if (width >= 1450) return height - 365
      else return height - 550
    },
    onDragAreaActive () {
      if (this.selectedView === 0) this.dragArea = true
    },
    onDragAreaNotActive () {
      this.dragArea = false
    },
    onDragAreaRequiredDocsActive () {
      if (this.selectedView === 1) this.dragAreaRequiredDocs = true
    },
    onDragAreaRequiredDocsNotActive () {
      this.dragAreaRequiredDocs = false
    },
    onStart (evnt) {
      this.dragAreaRequiredDocs = true
    },
    onEnd (evnt) {
      this.dragAreaRequiredDocs = false
    },
    async onChangeReqDocs (evnt) {
      if (evnt.added) this.addFieldsToWorkbench([evnt.added.element], evnt.added.newIndex, true)
      if (evnt.moved) {
        this.dragAreaRequiredDocs = false
        try {
          await this.updateCaseWorkbench({
            _id: this.caseData._id,
            workbenchId: this.workbench._id,
            payload: {
              movedRequiredWorkbenchDocument: evnt.moved.element,
              movedRequiredDocumentInWorkbench: true,
              oldIndex: evnt.moved.oldIndex,
              newIndex: evnt.moved.newIndex
            }
          })
        } catch (e) {
          console.error(e, 'error')
          this.addToast({
            title: this.$t('error|moving_document_fail'),
            snackbarColor: 'error',
            color: 'white'
          })
        }
      }
    },
    async onNewWorkbenchDocument (event) {
      if (event && event.added) {
        this.updateCaseWorkbench({
          _id: this.caseData._id,
          workbenchId: this.workbench._id,
          payload: {
            assignedDocuments: [ event.added.element._id ],
            index: event.added.newIndex
          }
        })
      }
      if (event && event.moved) {
        this.dragArea = false
        try {
          await this.updateCaseWorkbench({
            _id: this.caseData._id,
            workbenchId: this.workbench._id,
            payload: {
              movedWorkbenchDocument: event.moved.element._id,
              movedDocumentInWorkbench: true,
              oldIndex: event.moved.oldIndex,
              newIndex: event.moved.newIndex
            }
          })
        } catch (e) {
          console.error(e, 'error')
          this.addToast({
            title: this.$t('error|moving_document_fail_short'),
            snackbarColor: 'error',
            color: 'white'
          })
        }
      }
    },
    search (input) {
      this.searchText = input
    },
    setView (index) {
      this.selectedView = index
      localStorage.setItem('case-workbench-document-view', index)
    },
    resetRequiredDocDialog () {
      this.searchText = null
      this.activeReqDocuments = []
      this.customField = false
      this.customFieldInput = null
      this.toolbarActive = true
    },
    checkIfSuggestionTypeAvailableInDocuments (suggestion, workbench) {
      return workbench.assignedDocuments.find(doc => {
        return doc.info.document_type ? doc.info.document_type.toLowerCase() === suggestion.fieldKey.toLowerCase() : false
        // if (Array.isArray(suggestion.name)) {
        //   let arr = []
        //   suggestion.name.forEach(_name => {
        //     if (doc.info.document_type && doc.info.document_type.toLowerCase() === _name.toLowerCase()) arr.push(_name)
        //   })
        //   if (arr.length) return true
        // } else {
        //   return doc.info.document_type ? doc.info.document_type.toLowerCase() === suggestion.name.toLowerCase() : false
        // }
      })
    },
    assignWorkbenchDocument (workbench) {
      EventBus.$emit('dialog-documents', {
        documents: this.caseData.documents,
        fromWorkbench: true,
        callback: async (docs) => {
          const documents = uniqBy([...workbench.assignedDocuments, ...docs], '_id')
          let docIdsToBeSend = []
          documents.forEach(doc => {
            docIdsToBeSend.push(doc._id)
          })
          this.updateCaseWorkbench({
            _id: this.caseData._id,
            workbenchId: workbench._id,
            payload: {
              assignedDocuments: docIdsToBeSend
            }
          })
        }
      })
    },
    async removeWorkbenchDocument ({ workbench, file } = {}) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure'),
        title: this.$t('common|warning')
      })
      if (!res) return
      this.deleteCaseWorkbenchDocument({
        _id: this.caseData._id,
        workbenchId: workbench._id,
        payload: {
          docId: file._id
        }
      })
    },
    openRequiredDocumentDialog () {
      this.addNewRequiredDocDialog = true
    },
    async addFieldsToWorkbench (fields, index, drop) {
      try {
        await this.updateCaseWorkbench({
          _id: this.caseData._id,
          workbenchId: this.workbench._id,
          payload: {
            requiredDocuments: fields,
            addRequiredDocuments: true,
            fieldIndex: index || null,
            dragAndDrop: !!drop
          }
        })
        // this.resetRequiredDocDialog()
        this.addNewRequiredDocDialog = false
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|adding_required_document_failed'),
          snackbarColor: 'error',
          color: 'white'
        })
      }
    },
    async addCustomField (customFieldInput) {
      const field = {
        id: uuidv4(),
        name: customFieldInput,
        fieldType: 'file-input',
        fieldKey: customFieldInput.split('').join('_'),
        key: customFieldInput.split('').join('_')
      }
      this.activeReqDocuments.push(field)
      try {
        await this.updateCaseWorkbench({
          _id: this.caseData._id,
          workbenchId: this.workbench._id,
          payload: {
            requiredDocuments: this.activeReqDocuments,
            addRequiredDocuments: true
          }
        })
        // this.resetRequiredDocDialog()
        this.addNewRequiredDocDialog = false
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|adding_required_document_failed'),
          snackbarColor: 'error',
          color: 'white'
        })
      }
    },
    async removeRequiredDocument (suggestion) {
      let templateNames = []
      let templateArr = []
      let joinedName
      let res
      this.workbench.templates.forEach(temp => {
        const found = temp.questionnaire.fields.find(f => f.id === suggestion.id)
        if (found) {
          templateNames.push(temp.name)
          templateArr.push(temp)
        }
      })
      if (templateNames.length) {
        joinedName = templateNames.join(', ')
      }
      if (joinedName && joinedName.length) {
        res = await this.$dialog.confirm({
          type: 'warning',
          text: this.$t('common|are_you_sure') + ' ' + this.$t('fields|field_will_be_also_deleted') + ' ' + `${joinedName}` + ' ' + this.$t('questionnaire|questionnaire').toLowerCase(),
          title: this.$t('common|warning')
        })
        if (!res) return
        for (let i = 0; i < templateArr.length; i++) {
          const template = templateArr[i]
          try {
            const payload = {
              fieldToBeDeleted: suggestion,
              deleteFieldFromQuestionnaire: true
            }
            const _id = template.questionnaire._id
            const caseId = this.caseData._id
            const workbenchId = this.workbench._id
            const templateId = template._id
            await this.updateQuestionnaireInCase({ _id, caseId, workbenchId, templateId, payload })
          } catch (e) {
            console.error(e, 'error')
            this.addToast({
              title: this.$t('warning|questionnaire_update_failed'),
              color: 'white',
              snackbarColor: 'error'
            })
          }
        }
      } else {
        res = await this.$dialog.confirm({
          text: this.$t('common|are_you_sure'),
          title: this.$t('common|warning')
        })
        if (!res) return
        try {
          await this.updateCaseWorkbench({
            _id: this.caseData._id,
            workbenchId: this.workbench._id,
            payload: {
              fieldToBeDeleted: suggestion,
              deleteRequiredDocument: true
            }
          })
        } catch (e) {
          console.error(e, 'error')
          this.addToast({
            title: this.$t('warning|workbench_update_failed'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    },
    async addWorkbenchTemplate () {
      EventBus.$emit('dialog-add-template-to-workbench', {
        callback: async (templateRef) => {
          const res = await this.$dialog.prompt({
            title: this.$t('templates|template_name'),
            text: this.$t('actions|enter_template_name'),
            textField: {
              // Any addtional props/attrs that will be binded to v-text-field component
              type: 'text',
              outlined: true,
              dense: true,
              class: 'mt-6'
            },
            actions: [
              {
                text: this.$t('actions|submit'), color: 'primary'
              }
            ]
          })
          if (!res) return
          try {
            const payload = {
              templateRef,
              name: res
            }
            await this.addCaseWorkbenchTemplate({ _id: this.caseData._id, workbenchId: this.workbench._id, payload })
          } catch (e) {
            console.error(e, 'error')
          }
        }
      })
    },
    async deleteWorkbenchTemplate (workbench, template) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure'),
        title: this.$t('common|warning')
      })
      if (!res) return
      await this.deleteCaseWorkbenchTemplate({
        _id: this.caseData._id,
        workbenchId: workbench._id,
        templateId: template._id
      })
    },
    openAnswerDialog (template) {
      this.$refs.QuestionnaireAnswerDialog.templateData = template
      this.$refs.QuestionnaireAnswerDialog.inPreview = true
    },
    async uploadDocumentAction (document) {
      const _id = this.account._id
      let proId
      this.caseData.sharedWith.accounts.forEach((acc) => {
        const found = this.account.myProfessionals.accounts.find(a => a._id === acc._id)
        if (found) proId = found
      })
      const payload = {
        language: document.language,
        file: document.file,
        caseId: this.caseData._id,
        clientId: this.caseData.client._id,
        fromQuestionairre: true,
        professionalId: proId._id
      }
      return this.uploadDocument({ _id, payload })
    },
    async updateQuestionnaire (questionairre, issuer) {
      const filesToUpload = questionairre.fields.filter((f) => {
        return f.fieldType === 'file-input'
      })
      const documents = []
      if (filesToUpload.length) {
        for (let i = 0; i < filesToUpload.length; i++) {
          if (filesToUpload[i].value && !filesToUpload[i].isUploaded) {
            const fileData = await this.uploadDocumentAction(filesToUpload[i].value[0])
            documents.push(fileData.data._id)
            const fieldIndex = questionairre.fields.findIndex(qf => qf.id === filesToUpload[i].id)
            questionairre.fields[fieldIndex].isUploaded = true
            questionairre.fields[fieldIndex].documentId = fileData.data._id
          }
        }
      }
      await this.$axios.post(`/updateQuestionnaire/${questionairre._id}`, {
        fields: questionairre.fields,
        documents,
        _caseId: this.caseData._id,
        fromClient: issuer && issuer === 'from-client'
      })
      // this.addToast({
      //   title: 'The answers were successfully sent to your professional',
      //   color: 'white',
      // snackbarColor: 'success'
      // })
      this.$refs.QuestionnaireAnswerDialog.inPreview = false
      await this.fetchCase(this.caseData._id)
    },
    async shareWithClient (workbench, template) {
      template.sharedWithClient = true
      const _id = this.caseData._id
      const workbenchId = workbench._id
      const templateId = template._id
      const payload = {
        makeVisibleForClient: 'share'
      }
      try {
        await this.updateCaseWorkbenchTemplate({ _id, workbenchId, templateId, payload })
        this.addToast({
          title: this.$t('templates|successfully_shared_with_client'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (error) {
        console.error('error', error)
        this.addToast({
          title: this.$t('templates|share_with_client_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async stopSharingWithClient (workbench, template) {
      template.sharedWithClient = false
      const _id = this.caseData._id
      const workbenchId = workbench._id
      const templateId = template._id
      const payload = {
        makeVisibleForClient: 'unshare'
      }
      try {
        await this.updateCaseWorkbenchTemplate({ _id, workbenchId, templateId, payload })
        this.addToast({
          title: this.$t('templates|successfully_unshared'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (error) {
        console.error('error', error)
        this.addToast({
          title: this.$t('templates|unshare_with_client_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async updateTemplateName (workbench, template) {
      const _id = this.caseData._id
      const workbenchId = workbench._id
      const templateId = template._id
      const res = await this.$dialog.prompt({
        title: this.$t('actions|change_template_name'),
        text: this.$t('actions|enter_new_name'),
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text',
          outlined: true,
          dense: true,
          class: 'mt-6'
        },
        actions: [
          {
            text: this.$t('actions|submit'), color: 'primary'
          }
        ]
      })
      if (!res) return
      const payload = {
        newTemplateName: res
      }
      try {
        await this.updateCaseWorkbenchTemplate({ _id, workbenchId, templateId, payload })
        this.addToast({
          title: this.$t('templates|name_change_successful'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (error) {
        console.error('error', error)
        this.addToast({
          title: this.$t('templates|update_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    }
  }
}
