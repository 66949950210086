<template>
  <v-card
    width="150"
    height="140"
  >
    <v-row no-gutters>
      <v-col
        cols="1"
      >
        <v-sheet
          color="accent"
          class="left mt-2"
          width="8"
          height="90%"
        />
      </v-col>
      <v-col cols="11">
        <div
          v-if="template.name.length >= 40"
          class="mt-4"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="font-weight-bold ml-2 mt-n1"
                style="font-size: 12px;"
              >
                {{ $t('questionnaire|questionnaire') }}
              </div>
              <div
                v-bind="attrs"
                class="font-weight-medium ml-2"
                style="width: 110px; font-size: 11px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
                v-on="on"
              >
                {{ template.name }}
              </div>
            </template>
            <span>{{ template.name }}</span>
          </v-tooltip>
        </div>
        <!-- <div
          class="font-weight-bold ml-2 mt-n2"
          style="font-size: 12px;"
        >
          Questionnaire
          <div
            class="font-weight-medium"
            style="font-size: 10px; line-height: 1.3"
          >
            Document: {{ template.name }}
          </div>
        </div> -->
        <v-card-actions class="actions-position">
          <v-btn
            text
            rounded
            x-small
            color="primary"
            class="ml-n3"
            @click="$emit('open-answer-questionnaire', template)"
          >
            open
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiFile, mdiPencil, mdiTrashCanOutline } from '@mdi/js'

export default {
  props: {
    template: {
      type: Object,
      default: null
    },
    workbench: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        file: mdiFile,
        trash: mdiTrashCanOutline,
        edit: mdiPencil
      },
      selectedTemplate: null,
      navigation: {
        width: 450,
        borderSize: 6
      }
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast'
    })
  }
}
</script>

<style lang="scss" scoped>
  .stripe-position {
    position: absolute;
  }
  .left {
    position: absolute;
    left: 5px;
  }
  .actions-position {
    position: absolute;
    bottom: 0;
  }
</style>
