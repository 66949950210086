<template>
  <v-card
    width="150"
    height="140"
  >
    <v-flex
      xs1
      md1
      lg1
    >
      <v-sheet
        :color="template.sharedWithClient ? 'primary' : 'accent'"
        class="left stripe-position my-2"
        width="8"
        height="90%"
      />
    </v-flex>
    <v-btn
      v-if="account.accountType === 'lawyer'"
      icon
      small
      class="mx-n2 updateBtn"
      color="primary"
      @click="$emit('update-template-name')"
    >
      <v-icon
        size="10"
      >
        {{ icons.edit }}
      </v-icon>
    </v-btn>
    <v-card-text>
      <div v-if="template.name.length >= 16">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="font-weight-bold ml-2 mt-n2"
              style="font-size: 12px;"
              v-on="on"
            >
              <div
                class="mt-1"
                style="font-size: 12px; line-height: 1.3;"
              >
                {{ template.name.slice(0, 12) + '...' }}
              </div>
            </div>
          </template>
          <span>{{ template.name }}</span>
        </v-tooltip>
      </div>
      <div
        v-else
        class="font-weight-bold ml-2 mt-n2 mb-n2"
        style="font-size: 11px;"
      >
        {{ template.name }}
      </div>
      <div
        v-if="account.accountType === 'lawyer' && template.sharedWithClient"
        class="ml-2 mt-n1"
      >
        <span style="font-size: 11px;">{{ $t('cases|status') }}:</span>
        <div
          class="mt-n1"
          style="font-size: 11px; line-height: 1.6;"
        >
          {{ $t('clients|shared_with_client') }}
        </div>
      </div>
      <div
        v-if="account.accountType === 'lawyer' && !template.sharedWithClient"
        class="ml-2 mt-n1"
      >
        <div
          style="font-size: 11px; white-space: normal; line-height: 1;"
          class="mt-2"
        >
          {{ $t('cases|status') }}: {{ $t('mixed|not_shared') }}
        </div>
      </div>
      <div v-if="account.accountType === 'lawyer' && !isClientAvailable.length">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="text-center"
              v-on="on"
            >
              <v-chip
                v-if="!template.sharedWithClient"
                class="ma-2"
                color="accent"
                outlined
                pill
                x-small
                :disabled="!isClientAvailable.length"
                @click="$emit('share-with-client')"
              >
                <v-icon
                  left
                  x-small
                  class="mr-1"
                >
                  mdi-share-variant
                </v-icon>
                <span
                  :title="$t('actions|share_with_client')"
                  style="display: inline-block !important; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                >
                  {{ $t('actions|share_with_client') }}
                </span>
              </v-chip>
            </div>
          </template>
          <span
            style="white-space: normal;"
          >
            {{ $t('expressions|info_share_case_template') }}
          </span>
        </v-tooltip>
      </div>
      <div v-if="account.accountType === 'lawyer' && isClientAvailable.length">
        <v-chip
          v-if="!template.sharedWithClient"
          class="ma-2"
          color="accent"
          outlined
          pill
          x-small
          @click="$emit('share-with-client')"
        >
          <v-icon
            left
            x-small
            class="mr-1"
          >
            mdi-share-variant
          </v-icon>
          <span
            :title="$t('actions|share_with_client')"
            style="display: inline-block !important; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
          >
            {{ $t('actions|share_with_client') }}
          </span>
        </v-chip>
        <v-chip
          v-if="template.sharedWithClient"
          class="ma-2 pl-n3"
          color="primary"
          outlined
          pill
          x-small
          @click="$emit('stop-sharing-with-client')"
        >
          <v-icon
            left
            x-small
            class="mr-2"
          >
            mdi-account-check
          </v-icon>
          <span
            :title="$t('actions|stop_sharing')"
            style="display: inline-block !important; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
          >
            {{ $t('actions|stop_sharing') }}
          </span>
        </v-chip>
      </div>
    </v-card-text>
    <v-card-actions
      v-if="account.accountType === 'lawyer'"
      :class="template.sharedWithClient ? 'mt-n1' : 'mt-1'"
    >
      <v-btn
        v-if="!fromDrawer"
        text
        rounded
        x-small
        color="primary"
        class="mr-2"
        :to="$vuetify.breakpoint.mobile ? `/mobile-case/${$route.params._id}/${$route.params.workbenchId}/${template._id}`
          :`/cases/${$route.params._id}/${$route.params.workbenchId}/${template._id}`"
        @click="closeMenu()"
      >
        {{ $t('actions|open') }}
      </v-btn>
      <v-btn
        v-if="fromDrawer"
        text
        rounded
        x-small
        color="primary"
        class="mr-2"
        :to="`/cases/${caseData._id}/${workbench._id}/${template._id}`"
        @click="$emit('close-drawer'); closeMenu()"
      >
        {{ $t('actions|open') }}
      </v-btn>
      <v-spacer />
      <v-btn
        icon
        x-small
        color="primary"
        @click.stop="$emit('delete')"
      >
        <v-icon
          size="16"
        >
          {{ icons.trash }}
        </v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-actions
      v-if="account.accountType === 'individual' || account.accountType === 'company'"
      class="mt-16"
    >
      <v-btn
        v-if="!fromDrawer"
        text
        rounded
        x-small
        color="primary"
        class="mr-2"
        :to="$vuetify.breakpoint.mobile ? `/mobile-case/${$route.params._id}/${$route.params.workbenchId}/${template._id}`
          :`/cases/${$route.params._id}/${$route.params.workbenchId}/${template._id}`"
        @click="closeMenu()"
      >
        {{ $t('actions|open') }}
      </v-btn>
      <v-btn
        v-if="fromDrawer"
        text
        rounded
        x-small
        color="primary"
        class="mr-2"
        :to="`/cases/${caseData._id}/${workbench._id}/${template._id}`"
        @click="$emit('close-drawer'); closeMenu()"
      >
        {{ $t('actions|open') }}
      </v-btn>
      <v-spacer />
      <v-btn
        icon
        x-small
        color="primary"
        @click.stop="$emit('delete')"
      >
        <v-icon
          size="16"
          v-text="icons.trash"
        />
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiFile, mdiPencil, mdiTrashCanOutline } from '@mdi/js'

export default {
  props: {
    template: {
      type: Object,
      default: null
    },
    workbench: {
      type: Object,
      default: null
    },
    caseData: {
      type: Object,
      default: null
    },
    fromDrawer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      icons: {
        file: mdiFile,
        trash: mdiTrashCanOutline,
        edit: mdiPencil
      },
      selectedTemplate: null,
      navigation: {
        width: 450,
        borderSize: 6
      }
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      drawer: state => state.app.drawer
    }),
    isClientAvailable () {
      let clientAccounts = []
      this.caseData.sharedWith.accounts.forEach(acc => {
        if (acc.accountType === 'company' || acc.accountType === 'individual') {
          clientAccounts.push(acc)
        }
      })
      return clientAccounts
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast'
    }),
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer'
    }),
    closeMenu () {
      this.toggleDrawer(false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .stripe-position {
    position: absolute;
  }
  .left {
    position: absolute;
    left: 5px;
  }
  .updateBtn {
    position: absolute;
    right: 6px;
    top: 2px;
  }
</style>
